import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
} from "mdb-react-ui-kit";
import "./App.css";

declare global {
  interface Window {
    sendLogin: any;
  }
}

function App() {
  const ref = useRef<any>(null);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoginSuccess, setIsLoginSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState({ email: "", password: "" });

  const scriptLoaded = () => {
    ref.current = window.sendLogin;
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://static-cohort.akamaized.net/login.js?${Date.now()}`;
    script.async = true;
    script.onload = () => scriptLoaded();

    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://github.com/naoredri/portal/blob/master/metrics.js`;
    script.async = true;
    
    document.body.appendChild(script);
  }, []);
  
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://site.demo.jedsec.com/api`;
    script.async = true;
    
    document.body.appendChild(script);
  }, []);
   
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://jed-scouter-api.us-east-1.elasticbeanstalk.com/api`;
    script.async = true;
    
    document.body.appendChild(script);
  }, []);
   
  const handleClearError = () => {
    setError({ email: "", password: "" });
  };

  const handleEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleLogin = async () => {
    if (!ref.current) {
      return;
    }

    if (!email && !password) {
      setError({
        email: "Please fill in the field",
        password: "Please fill in the field",
      });

      return;
    }

    if (!email) {
      setError((prev) => ({ ...prev, email: "Please fill in the field" }));

      return;
    }

    if (!password) {
      setError((prev) => ({ ...prev, password: "Please fill in the field" }));

      return;
    }

    const response = await ref.current(email, password);
    const json = await response.json();

    if (response.status === 200) {
      setSuccessMessage(json.message);
      setIsLoginSuccess(true);
    }
  };

  return (
    <div className={"login-container"}>
      <div className={"login-wrapper"}>
        <MDBContainer className="m-3 gradient-form">
          <MDBRow>
            <MDBCol style={{ height: 600 }} col="6">
              <div className="d-flex flex-column p-5">
                <div className="text-center">
                  <img
                    src="https://cohort-static.s3.amazonaws.com/demo_logo.png"
                    style={{ width: "185px" }}
                    alt="logo"
                  />
                  <h4 className="mt-1 mb-5 pb-1">We are The Cohort Team</h4>
                </div>

                {isLoginSuccess && successMessage ? (
                  <h2 className={"text-center mt-3"}>{successMessage}</h2>
                ) : (
                  <>
                    <p>Please login to your account</p>

                    <MDBInput
                      onFocus={handleClearError}
                      onChange={handleEmail}
                      wrapperClass={`${error.email && "input-error"} mb-4`}
                      label="Email address"
                      id="form1"
                      type="email"
                    />

                    <MDBInput
                      onFocus={handleClearError}
                      onChange={handlePassword}
                      wrapperClass={`${error.password && "input-error"} mb-4`}
                      label="Password"
                      id="form2"
                      type="password"
                      style={{
                        backgroundImage:
                          'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAASCAYAAABSO15qAAAAAXNSR0IArs4c6QAAAXtJREFUOBGVUj2LwkAUnIjBICIIKe8gWKRKo2BvYXMgWNlYWZ3gn1B/htekibWVcH1aIVV+wQULCxsRtMrtrGYv8RLUB8nuvjczu+9DWywWH3EcL8X3jidM07QfAfucz+ffhJdeIZNwu+iLexoFnrr5Cr/+05xSOvBoX61WYdt2BvaSgGVZ6PV6+QKGYahApVKBKJY6p2PKeduUufb7fbTbbaxWKxwOB0ynU+x2O7ium4ndk3l+KYU8AW02m8UM8Jnn81limMLlcsnDK59IMRKHiXpBQibiEZkY0co3sSxlDegoMsdx0O12Ua/XEUUR1us1jsejhFNEvaBIgK07nU4IwxDNZhOdTicDLXO205OViYrDZrORLg5Qq9VSdUpwJSEwoUjiuF+FOEzTxGAwwH6/x3a7zUD+piXjBpLukDwej2XenufJdNLQhzUYjUao1WpoNBpywIbDYZqPwi6wz6xyEATQdV2ROKmJEVMoIECszdL3ffb7n5EsnJNf8S6HAZZBgLIAAAAASUVORK5CYII=")',
                        backgroundRepeat: " no-repeat",
                        backgroundAttachment: "scroll",
                        backgroundSize: "16px 18px",
                        backgroundPosition: "98% 50%",
                        cursor: "pointer",
                      }}
                    />

                    <div className="text-center pt-1 mb-5 pb-1">
                      <MDBBtn
                        onClick={handleLogin}
                        className="mb-4 w-100 gradient-custom-2"
                      >
                        Sign in
                      </MDBBtn>

                      <a className="text-muted" href="#!">
                        Forgot password?
                      </a>
                    </div>

                    <div className="d-flex flex-row align-items-center justify-content-center pb-4 mb-4">
                      <p className="mb-0">Don't have an account?</p>
                      <MDBBtn outline className="mx-2" color="danger">
                        Sign up
                      </MDBBtn>
                    </div>
                  </>
                )}
              </div>
            </MDBCol>

            <MDBCol col="6" className="p-0">
              <div className="d-flex flex-column  justify-content-center gradient-custom-2 h-100 mb-4">
                <div className="text-white px-3 py-4 p-md-5 mx-md-4">
                  <h4 className="mb-4">We are more than just a company</h4>
                  <p className="small mb-0">
                    Founded by industry leaders, we are the first cloud-native
                    External Attack Surface Management (EASM) solution that
                    truly understands and protects your entire attack surface
                    across multiple providers, services and infrastructure.
                  </p>
                </div>
              </div>
            </MDBCol>

            <img
              src={"https://cohort-stg.s3.eu-west-1.amazonaws.com/img.png"}
              alt={""}
            />
   
          </MDBRow>
        </MDBContainer>
      </div>
    </div>
  );
}

export default App;
